/* eslint-disable react-hooks/exhaustive-deps */
import { create } from '@lottiefiles/lottie-interactivity';
import { Player } from '@lottiefiles/react-lottie-player';
import { AnimationItem } from 'lottie-web';
import { FC, useEffect, useState } from 'react';

import { StyledReactSVG, Wrapper } from './MAnimatedIllustration.styles';

import { MAnimatedIllustrationType } from '../../types/storyblok';

export const MAnimatedIllustration: FC<MAnimatedIllustrationType> = ({
  disableMaxWidth: isMaxWidthDisabled = false,
  height,
  illustration,
  lottieAnimationData,
  lottieCustomActions,
  lottieMode,
  width,
}) => {
  const [lottieInstance, setLottieInstance] = useState<AnimationItem>();

  useEffect(() => {
    if (lottieInstance?.isLoaded && lottieMode !== 'autoplay') {
      const defaultActions = [{ type: 'seek', visibility: [0.1, 0.9], frames: [0, lottieInstance?.totalFrames] }];

      let parsedCustomActions;

      try {
        if (lottieCustomActions) {
          parsedCustomActions = JSON.parse(lottieCustomActions);
        }
      } catch (e) {
        //
      }

      create({
        mode: 'scroll',
        player: lottieInstance,
        actions: parsedCustomActions ?? defaultActions,
      });
    }
  }, [lottieInstance]);

  return (
    <Wrapper $isMaxWidthDisabled={isMaxWidthDisabled} data-testid="animated-illustration">
      {lottieAnimationData?.filename && (
        <Player
          lottieRef={(instance) => {
            setLottieInstance(instance);
          }}
          {...(lottieMode === 'autoplay' && { autoplay: true, loop: true })}
          src={lottieAnimationData.filename}
          style={{ aspectRatio: `${width ?? '1'} / ${height ?? '1'}` }}
        />
      )}
      {illustration && !lottieAnimationData && <StyledReactSVG src={`/assets/illustrations/${illustration}.svg`} />}
    </Wrapper>
  );
};

export default MAnimatedIllustration;
