import { ReactSVG } from 'react-svg';

import styled, { css, keyframes } from '@lichtblick/styled';
import { MediaBelowMedium } from '@lichtblick/theme';

export const Wrapper = styled.div<{ $isMaxWidthDisabled: boolean }>`
  ${({ $isMaxWidthDisabled }) =>
    !$isMaxWidthDisabled &&
    css`
      ${MediaBelowMedium} {
        width: 64vw;
        margin: 0 auto;
        max-width: 320px;
      }
    `};
`;

const showSVG = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

export const StyledReactSVG = styled(ReactSVG)`
  svg {
    animation-delay: 0.5s;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-name: ${showSVG};
    display: block;
    height: 100%;
    opacity: 0;
    width: 100%;
  }
`;
